import ContentSocial from './ContentSocial'
import LinkBack from '../AmbientalComponent/LinkBack'
import '../styles.scss'

const SocialComponent = () => {
  return (
    <main className="main-sustentabilidade-subpage">
      <div className="background-full">
        <section className="content--cta-banner">
          <LinkBack />
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-social.png"
            alt="banner principal"
            className="desk"
          />
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/banner-principal-social-mobile.png"
            alt="banner principal"
            className="mob"
          />
        </section>
        <section className="content--text">
          <div className="av-container">
            <div className="av-col-xs-24">
              <ContentSocial />
            </div>
            <div className="av-col-xs-24">
              <div className="av-col-xs-24">
                <a
                  href="https://www.decathlon.com.br/Institucional/sustentabilidade-brasil"
                  className="cta-voltar"
                >
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  )
}

export default SocialComponent
