const TopContent = () => {
  return (
    <>
      <article className="article">
        <h2 className="article_title">BEM ESTAR</h2>
        <p className="article_text">
          Somos esportivos e buscamos impulsionar o desempenho por meio da
          satisfação em trabalharmos juntos.
          <br />
          <strong>
            60% dos colegas de equipe * reportam que ficam felizes em vir
            trabalhar todas as manhãs, para apoiar nosso projeto, o projeto
            deles e o objetivo geral da empresa * que respondeu “Sim,
            completamente”.
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">DIVERSIDADE E INCLUSÃO</h2>
        <p className="article_text">
          Somos uma empresa inclusiva com uma diversidade excepcional (idade,
          gênero, nacionalidade, etc.). Nossas equipes refletem a sociedade
          local e mantemos o que nos torna únicos. Isso proporciona a todos
          novas oportunidades em inovação, práticas humanas e igualdade de
          oportunidades.
          <br />
          <strong>50% mulheres e 50% homens</strong>
        </p>
        <p className="article_text">
          <strong>50% dos líderes de equipe * são mulheres</strong>
          <br />
          <strong>
            * Colegas de equipe com função hierárquica no comando de pelo menos
            uma pessoa.
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">DIREITOS HUMANOS</h2>
        <p className="article_text">
          Trabalhamos com os nossos fornecedores de produtos assinados e
          acessórios para lojas, para melhorar continuamente as condições de
          trabalho de acordo com o nosso código de conduta.
          <br />
          <strong>
            90% dos fornecedores de nível 1 * são classNameificados
          </strong>
          <br />
          <strong>A, B ou C de acordo com nossa grade de auditoria</strong>
          <br />
          <strong>
            * Fornecedor de nível 1: Fornecedor vinculado contratualmente a uma
            empresa DECATHLON para a fabricação ou montagem de produtos acabados
            / semiacabados (via contrato de manufatura) ou para o fornecimento
            de matérias-primas ou componentes (via contrato de compra),
            recebendo ordens de compra e faturando diretamente a referida
            empresa.
          </strong>
        </p>
        <p className="article_text">
          Nossos colaboradores e compradores estão cientes das questões de
          Direitos Humanos em nossas operações e de nossos fornecedores, para
          uma melhor integração em nossos processos e compras.
          <br />
          <strong>
            100% de nossa equipe envolvida é treinada sobre direitos humanos
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">HABILIDADES E DESENVOLVIMENTO</h2>
        <p className="article_text">
          Nossa cultura de progresso e desempenho está ligada a um forte desejo
          de que nossos companheiros de equipe sejam autônomos e responsáveis.
          Eles escolhem seu(s) projeto(s) e sua(s) missão(ões) e desenvolvem
          novas habilidades e talentos, criando oportunidades de carreira. Eles
          são ajudados a progredir em sua(s) missão(ões), beneficiando-se de uma
          abordagem rigorosa e cuidadosa ditada por nossa estrutura gerencial
          (revisões individuais regulares, reuniões de grupo, etc.).
          <br />
          <strong>
            100% dos colegas de equipe * afirmam que assumem a responsabilidade
            por seu progresso
          </strong>
          <br />
          <strong>
            * que responderam “sim, totalmente” ou “sim, geralmente”.
          </strong>
        </p>
      </article>
    </>
  )
}

export default TopContent
