import TopContent from './TopContent'

const ContentSocial = () => {
  return (
    <section className="section-content">
      <TopContent />
      <article className="article">
        <h2 className="article_title">VALOR DE COMPARTILHAMENTO</h2>
        <p className="article_text">
          Criamos valor de forma sustentável e compartilhamos os frutos dessa
          criação.
          <br />
          Isso nos obriga a ser ambiciosos em nossos objetivos e a adotar os
          recursos para alcançá-los. Criamos valor (humano, ambiental e
          econômico).
          <br />
          <strong>
            100% dos colaboradores elegíveis são coproprietários da DECATHLON
            (se assim o desejarem), graças ao regime de acionistas dos
            funcionários
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">AGILIDADE, ORGANIZAÇÃO EM REDE</h2>
        <p className="article_text">
          Aculturar nossos colegas de equipe para o digital, a fim de fortalecer
          seu compromisso e auxiliar no processo de transformação.
          <br />
          <strong>
            100% dos países são impulsionados por um índice de maturidade
            digital
          </strong>
        </p>
      </article>
      <article className="article">
        <h2 className="article_title">
          CRIANDO UMA IMPRESSÃO COM NOSSOS PRODUTOS
        </h2>
        <p className="article_text">
          Aprovamos nossos produtos acabados e componentes nos estágios de
          design, desenvolvimento e produção, para que cumpram o propósito
          pretendido de que sejam seguros e sustentáveis ao longo do tempo.
          Trabalhamos de forma proativa para eliminar gradualmente produtos
          químicos potencialmente perigosos de nossos produtos esportivos.
          <br />
          <strong>76% dos nossos usuários estão satisfeitos **</strong>
          <br />
          <strong>
            ** Usuários que deram uma pontuação de 5/5 em nossas avaliações de
            clientes.
          </strong>
        </p>
      </article>
    </section>
  )
}

export default ContentSocial
